import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home';
import { Route, Routes } from 'react-router-dom';
import { DonationPolicy } from './Components/DonationPolicy';
import { useEffect, useState } from 'react';
import { API_URL } from './config';
import Program from './Components/Program';
import AdminPage from './Components/AdminPage';
import './App.css';
import { MakeADonation } from './Components/MakeADonation';
import Programs from './Components/Programs';

function App() {
  const [programs, setPrograms] = useState();
  useEffect(() => {
    fetchPrograms();
  }, []);

  const fetchPrograms = async () => {
    const res = await fetch(`${API_URL}/programs`);
    const data = await res.json();
    console.log(data);
    setPrograms(data.programs);
  }


  return (
    <div className="App">
      <Header programs={programs} />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/admin" element={<AdminPage programs={programs} fetchPrograms={fetchPrograms} />} exact />
        <Route path="/donation_policy" element={<DonationPolicy />} exact />
        <Route path="/donation" element={<MakeADonation />} exact />
        <Route path="/programs" element={<Programs programs={programs} />} exact />
        <Route path="/programs/:programName" element={<Program programs={programs} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
