import LogoDark from "../Icons/LogoDark.png";
// import FacebookIcon from "../Icons/FacebookIcon.png";
// import InstagramIcon from "../Icons/InstagramIcon.png";
// import TwitterIcon from "../Icons/TwitterIcon.png";
import YoutubeIcon from "../Icons/YoutubeIcon.png";
import { PHONE_NUMBER, YOUTUBE_CHANNEL_URL } from "../config.js";

const Footer = () => {
    return (
        <div className="Footer">
            <div className="FooterLogoAndContact">
                <div className="FooterLogo"><img src={LogoDark} alt="logo dark" /></div>
                <div className="FooterSocialAndContact">
                    <div className="FooterSocialAndContactSocialIcons">
                        {/* <img src={FacebookIcon} alt="Facebook Icon" />
                        <img src={InstagramIcon} alt="Instagram Icon" />
                        <img src={TwitterIcon} alt="Twitter Icon" /> */}
                        <a href={YOUTUBE_CHANNEL_URL} target="_blank" rel="noopener noreferrer">
                            <img src={YoutubeIcon} alt="YouTube Icon" />
                        </a>
                    </div>
                    <div className="FooterSocialAndContactContactInfo">
                        <div className="FooterSocialAndContactContactInfoNumber">
                            {PHONE_NUMBER}
                        </div>
                        <div className="FooterSocialAndContactContactInfoEmail">
                            khayrfoundationusa@gmail.com
                        </div>
                    </div>
                </div>
            </div>
            <div className="FooterColumn">
                {/* <div className="FooterColumnText">Recent Programs</div>
                <div className="FooterColumnText">About Us</div>
                <div className="FooterColumnText">Make a Donation</div>
                <div className="FooterColumnText">Financial Disclosures</div> */}
            </div>
            <div className="FooterColumn">
                {/* <div className="FooterColumnText">Emergency Aid</div>
                <div className="FooterColumnText">Family Assistance</div>
                <div className="FooterColumnText">News and Media</div>
                <div className="FooterColumnText">Contact Us</div> */}
            </div>
        </div>
    )
}

export default Footer;