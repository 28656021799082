import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import RegionIcon from '../Icons/RegionIcon.svg';
import PlayButton from '../Icons/PlayButtonOrng.svg';

import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';

const Header = () => {
    return (
        <div className="BannerHeader" style={{ justifyContent: "flex-start" }}>
            <div className="BannerHeaderContent1">
                Current Relief Programs
            </div>
        </div>
    )
}

const ProgramBlock = ({ program: { SK: name, description, regions, slideshowPhotos, videoUrl }, blockType }) => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === slideshowPhotos.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? slideshowPhotos.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = slideshowPhotos.map((item, index) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index}
            >
                <img src={item.imageUrl} alt={item.imageDescription} className="CarouselImage" />
                <div className="CarouselCaption">{item.imageDescription}</div>
            </CarouselItem>
        );
    });

    const ProgramBlockContainer = () => (
        <div className="ProgramBlockInfoContainer">
            <div className="ProgramBlockInfoTitle">
                {name}
            </div>
            <div className="ProgramBlockInfoText">
                {description}
            </div>
            {regions?.length && (
                <div className="ProgramBlockInfoRegionsContainer">
                    <div className="ProgramBlockInfoRegionsTitleContainer">
                        <div className="ProgramBlockInfoRegionIcon">
                            <img src={RegionIcon} alt="Region Icon" />
                        </div>
                        <div className="ProgramBlockInfoRegionsTitle">Regions</div>
                    </div>
                    <div className="ProgramBlockInfoRegionsBulletItems">
                        <ul>
                            {regions.map((region, index) => (
                                <li key={index}>{region}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            <div className="ButtonPrimary" style={{ width: "263px" }} onClick={() => navigate("/donation")}>Make a Donation</div>
        </div>


    )

    const ProgramBlockPhotoContainer = () => (
        <div className="ProgramBlockPhotoContainer">
            <div className="ProgramBlockPhoto">
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                >
                    <CarouselIndicators items={slideshowPhotos} activeIndex={activeIndex} onClickHandler={goToIndex} />
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                </Carousel>
            </div>
            {videoUrl && (
                <a href={videoUrl} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                    <div className="ProgramBlockPhotoVideoLinkContainer">
                        <div className="ProgramBlockPhotoVideoLinkText">
                            VIDEO: Our volunteers in action
                        </div>
                        <img src={PlayButton} alt="play button" />
                    </div>
                </a>
            )}
        </div>
    )

    if (blockType === 'even')
        return (
            <div className="ProgramBlockContainer">
                <ProgramBlockContainer />
                <ProgramBlockPhotoContainer />
            </div >
        )

    // odd
    return (
        <div className="ProgramBlockContainer" style={{ backgroundColor: 'var(--lighter-gray, #F2F2F2)' }}>
            <ProgramBlockPhotoContainer />
            <ProgramBlockContainer />
        </div >
    )
}


const Programs = ({ programs }) => {
    if (!programs) return;
    return (
        <div>
            <Header />
            {programs.map((program, index) => {
                const blockType = index % 2 === 0 ? 'even' : 'odd';
                return <ProgramBlock key={index} program={program} blockType={blockType} />;
            })}
        </div>
    );
}

export default Programs;
