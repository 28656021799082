import React from "react";
import Icon from '../Icons/Icon.svg';
// import VenmoLogo from '../Icons/VenmoLogo.png';
import ZelleLogo from '../Icons/ZelleLogo.png';
// import GoFundMeLogo from '../Icons/GoFundMeLogo.png';
// import LaunchGoodLogo from '../Icons/LaunchGoodLogo.png';
import QRCode from 'qrcode.react';


export const MakeADonation = () => {
    return (
        <div className="make-a-donation">
            <Header />
            <DonateWithMobileDeviceSection />
            <hr className="divider" /> {/* This is the line divider */}
            {/* <MoreWaysToDonate /> */}
            <DonateViaCreditCardOrByMail />
        </div>
    );
};

const Header = () => {
    return (
        <div className="BannerHeader">
            <div className="BannerHeaderContent1">
                Make a Donation
            </div>
            <div className="BannerHeaderContent2">
                Khayr Foundation accepts donations via Zelle. You can also mail us a check.
            </div>
        </div>
    )
}

const DonateWithMobileDeviceSection = () => {
    return (
        <div className="DonateWithMobileDeviceSectionContainer">
            <div className="Icon"><img src={Icon} alt="icon" /> </div>
            <div className="DonateWithMobileDeviceSectionTitle">Donate with your mobile device</div>
            <div className="DonateWithMobileDeviceSectionText">
                If you link your bank account to money transfer services such as Zelle, use your phone’s camera to scan the QR code below and transfer your donation directly to Khayr Foundation USA. You can also send donations to the username or address provided.
            </div>
            <QRCards />
        </div>
    )
}

const QRCards = () => {
    const qrCodeStyle = {
        width: '197px',
        height: '192px',
        flexShrink: 0
    };
    return (
        <div className="QRCardsContainer">
            <div className="QRCard">
                <img src={ZelleLogo} alt="zelle logo" />
                <QRCode value="https://enroll.zellepay.com/qr-codes?data=ewogICAgInRva2VuIjogIjE3MDM1NjgxOTgwIiwKICAgICJuYW1lIjogIktIQVlSIEZPVU5EQVRJT04gVVNBIiwKICAgICJhY3Rpb24iOiAicGF5bWVudCIKfQ" style={qrCodeStyle} />
                <div className="QRCodeTextContainer">
                    <div className="QRCodeText">Scan the QR code above or use Zelle to send your donation to:</div>
                    <div className="QRCodeText" style={{ color: "#7F18D9", fontWeight: "700" }}>(703) 568-1980</div>
                </div>
            </div>
            {/* <div className="QRCard">
                <img src={VenmoLogo} alt="venmo logo" />
                <QRCode value="https://account.venmo.com/u/khayrfoundation" style={qrCodeStyle} />
                <div className="QRCodeTextContainer">
                    <div className="QRCodeText">Scan the QR code above or use Venmo to send your donation to:</div>
                    <div className="QRCodeText" style={{ color: "#3F94D0", fontWeight: "700" }}>@khayrfoundationusa</div>
                </div>
            </div> */}
        </div>
    )
}

// const MoreWaysToDonate = () => {
//     return (
//         <div className="MoreWaysToDonateContainer">
//             <div className="MoreWaysToDonateText">More ways to donate to our relief efforts and programs</div>
//             <a href="https://www.launchgood.com/" target="_blank" rel="noopener noreferrer">
//                 <img src={LaunchGoodLogo} alt="launchgood logo" />
//             </a>
//             <a href="https://www.gofundme.com/" target="_blank" rel="noopener noreferrer">
//                 <img src={GoFundMeLogo} alt="gofundme logo" />
//             </a>
//         </div>
//     )
// }

const DonateViaCreditCardOrByMail = () => {
    return (
        <div className="DonateViaCreditCardOrByMailContainer">
            {/* <div className="DonateViaCreditCardOrByMailContainerCardPayment">
                TODO: add donate via credit card section once we figure out cc payments. Dont forget divider
                <div className="DonateViaCreditCardOrByMailContainerCardPaymentText">
                    Donate via credit card
                </div>
            </div> */}
            <div className="DonateViaCreditCardOrByMailContainerMailCheck">
                <div className="DonateViaCreditCardOrByMailContainerMailCheckTitle">
                    Donate a check by mail
                </div>
                <div className="DonateViaCreditCardOrByMailContainerMailCheckText">
                    <p><strong>Make checks payable to:</strong></p>
                    <p>Khayr Foundation USA</p>
                    <p>3506 Commodore Ct., Herndon, VA 20171</p>
                </div>
            </div>
        </div>
    )
}