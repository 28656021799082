const DonationPolicyText = ({ includeIRSItem }) => {
    return (
        <div>
            <ul>
                <li>Unless otherwise noted, Khayr Foundation USA will use your gift for the project(s) that Khayr Foundation USA determines is in need of funds.</li>
                <li>Khayr Foundation USA reserves the right to move funds to support other project(s) that Khayr Foundation USA determines is more urgent or if the program that was selected has met its financial goals.</li>
                <li>Khayr Foundation USA is recognized as tax-exempt under section 501(c)(3) of the Internal Revenue Code.</li>
                {includeIRSItem && <li>Khayr Foundation USA has filed a timely application for recognition as tax-exempt under section 501(c)(3) of the Internal Revenue Code, but the recognition has not yet been approved by the IRS. When the IRS approves a timely filed exemption application, exempt status is recognized back to the date the organization was created. If the organization ultimately qualifies for exemption for the period in which the contribution is made, the contribution will be tax-deductible by the donor. Alternatively, if the organization ultimately does not qualify for exemption, then the contribution will not be tax deductible.</li>}
                <li>A portion of your donation will go toward administrative costs.</li>
            </ul>
        </div>
    )
}

const DonationPolicy = () => {
    return (
        <div>
            <h1>Disclaimers</h1>
            <DonationPolicyText includeIRSItem />
        </div>
    )
}

export { DonationPolicyText, DonationPolicy };