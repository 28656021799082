import logo from '../Icons/Logo.png';
import phoneIcon from '../Icons/PhoneIcon.png';
import { useNavigate } from "react-router-dom";
import { PHONE_NUMBER } from '../config';

const Header = ({ programs }) => {
    // const programNames = programs ? programs.map(({ SK }) => SK) : [];
    const navigate = useNavigate();

    return (
        <div>
            <div className='TopBar'>
                <span className="TopBarContact" >
                    <img src={phoneIcon} alt="phone icon" /> <span className="TopBarPhoneNumber">{PHONE_NUMBER}</span>
                </span>
            </div>
            <div className='HeaderNav'>
                <div className="Logo" onClick={() => navigate("/")}><img src={logo} alt="logo" /></div>
                <div className='Navigation'>
                    <div className='NavLink' onClick={() => navigate("/programs")}> <div className='NavLinkText'> Programs</div></div>
                    {/* <div className='NavLink'> <div className='NavLinkText'> About Us</div></div>
                    <div className='NavLink'> <div className='NavLinkText'> Financial Disclosures</div></div>
                    <div className='NavLink'> <div className='NavLinkText'> Contact Us</div></div> */}
                    <div className="ButtonSecondary" onClick={() => navigate("/donation")}>Donate Now</div>
                </div>
            </div>
        </div>
    )
}

export default Header;
