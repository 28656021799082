import { useState } from "react";
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, CardText, Col, Container, Form, FormGroup, Input, Label, Row, UncontrolledCarousel } from "reactstrap";
import { API_URL } from "../config";

const ProgramCard = ({ program, adminAPIKey, fetchPrograms }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [errorUpdating, setErrorUpdating] = useState(false);
    const [errorDeleting, setErrorDeleting] = useState(false);
    const [editedProgram, setEditedProgram] = useState({ ...program, regions: program.regions || [] });

    const handleChange = ({ target: { name, value } }) => {
        const _editedProgram = { ...editedProgram };

        console.log({ name });

        if (name.startsWith('slideshowPhoto:')) {
            const i = parseInt(name.split('slideshowPhoto:')[1]);
            const isUrl = name.endsWith(':url');
            if (isUrl) {
                _editedProgram.slideshowPhotos[i].imageUrl = value;
            } else {
                _editedProgram.slideshowPhotos[i].imageDescription = value;
            }

        }

        else {
            _editedProgram[name] = value;
        }

        console.log({ _editedProgram })
        setEditedProgram(_editedProgram);
    }

    const updateProgram = async () => {
        const res = await fetch(`${API_URL}/programs`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': adminAPIKey
            },
            // body: '{"name": "Inmate Family Assistance", "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." ,"slideshowPhotos": ["https://cdn.theatlantic.com/media/mt/science/cat_caviar.jpg", "https://thumbs.dreamstime.com/b/kitten-lies-money-dollars-euros-looks-frame-holding-bills-its-paws-concept-wealth-prosperity-photo-196044184.jpg"] , "videoUrl": "https://www.youtube.com/watch?v=GpcaJQ40q1Y&ab_channel=RoyaltyFree%E2%80%94Videosforcontentcreators" }',
            body: JSON.stringify({
                ...editedProgram,
                name: editedProgram.SK
            })
        });
        if (!res.ok) {
            setErrorUpdating(true);
            return;
        }

        await fetchPrograms();
        setErrorUpdating(false);
        setIsEditing(false);
    }

    const deleteProgram = async () => {
        if (!window.confirm(`Are you sure you want to delete the '${program.SK}' program? This change can't be undone.`))
            return;

        const res = await fetch(`${API_URL}/programs?name=${encodeURIComponent(program.SK)}`, {
            method: 'DELETE',
            headers: {
                'x-api-key': adminAPIKey
            }
        });

        if (!res.ok) {
            setErrorDeleting(true);
            return;
        }

        await fetchPrograms();
        setErrorDeleting(false);
    }

    const handleRemoveRegion = (i) => {
        const newRegions = [...editedProgram.regions];
        newRegions.splice(i, 1);
        setEditedProgram({ ...editedProgram, regions: newRegions });
    }

    const AddRegionInput = () => {
        const [regionInput, setRegionInput] = useState('');

        const handleAddRegion = (e) => {
            e.preventDefault();
            if (regionInput) {
                setEditedProgram({
                    ...editedProgram,
                    regions: [...editedProgram.regions, regionInput]
                });
                setRegionInput('');
            }
        }

        return (
            <div>
                <Input type="text" value={regionInput} onChange={(e) => setRegionInput(e.target.value)} placeholder="Enter a region" />
                <Button color="primary" style={{ marginTop: '10px' }} onClick={handleAddRegion}>Add Region</Button>
            </div>
        );
    }

    const {
        videoUrl,
        description,
        slideshowPhotos,
        SK: name
    } = program;


    if (isEditing) {
        return (
            <div>
                <Card>
                    <Container>
                        <Form>
                            <FormGroup>
                                <Label>Name (not editable)</Label>
                                <Input type="text" name="programName" id="programName" value={editedProgram.SK} disabled />
                            </FormGroup>
                            <FormGroup>
                                <Label>Description</Label>
                                <Input rows="10" type="textarea" name="description" id="description" value={editedProgram.description} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Embedded YouTube video url</Label>
                                <Input type="text" name="videoUrl" id="videoUrl" value={editedProgram.videoUrl} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Slideshow photo urls and descriptions</Label>
                                {editedProgram.slideshowPhotos.map((photo, i) =>
                                    <div key={i}>
                                        <Input type="text" name={`slideshowPhoto:${i}:url`} id={`slideshowPhoto:${i}:url`} value={photo.imageUrl} onChange={handleChange} placeholder="Photo URL" />
                                        <Input type="text" name={`slideshowPhoto:${i}:description`} id={`slideshowPhoto:${i}:description`} value={photo.imageDescription} onChange={handleChange} placeholder="Photo Description" />
                                    </div>
                                )}

                                <div style={{ marginTop: '10px' }}>
                                    <Button
                                        color="danger"
                                        disabled={editedProgram.slideshowPhotos.length <= 1}
                                        onClick={(e) => { e.preventDefault(); setEditedProgram({ ...editedProgram, slideshowPhotos: [...editedProgram.slideshowPhotos.slice(0, editedProgram.slideshowPhotos.length - 1)] }) }}
                                    >-</Button>{' '}
                                    <Button
                                        color="primary"
                                        disabled={editedProgram.slideshowPhotos.length >= 10}
                                        onClick={(e) => { e.preventDefault(); setEditedProgram({ ...editedProgram, slideshowPhotos: [...editedProgram.slideshowPhotos, ''] }) }}
                                    >+</Button>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Regions</Label>
                                <AddRegionInput />
                                {editedProgram.regions.map((region, i) => (
                                    <Badge color="primary" key={i} className="mr-2 mb-2">
                                        {region}
                                        <span
                                            style={{ cursor: 'pointer', marginLeft: '5px' }}
                                            onClick={() => handleRemoveRegion(i)}
                                        >
                                            x
                                        </span>
                                    </Badge>
                                ))}
                            </FormGroup>
                        </Form>
                    </Container>
                    <CardFooter>
                        {errorUpdating && <div style={{ textAlign: "center", color: "red" }}>
                            Error Updating Program
                        </div>}
                        <div style={{ float: "right" }}>
                            <Button color="secondary" onClick={() => setIsEditing(false)}>Cancel</Button>
                            {' '}
                            <Button color="primary" onClick={() => updateProgram(true)}>Save</Button>
                        </div>
                    </CardFooter>
                </Card>
            </div>
        )
    }

    return (
        <div style={{ marginBottom: "50px" }}>
            <Card>
                <CardHeader tag="h4">{name}</CardHeader>
                <CardBody>
                    <CardText><b>Description:</b> {description}</CardText>
                    <Row>
                        <Col>
                            <CardText><b>Embedded Youtube Video Url:</b></CardText>
                            <div style={{ height: "50vh", textAlign: "center" }}>
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={videoUrl}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />
                            </div>
                        </Col>
                        <Col>
                            <CardText><b>Slideshow Photos:</b></CardText>
                            <div style={{ marginTop: "20px", textAlign: 'center' }}>
                                <UncontrolledCarousel items={slideshowPhotos.map(({ imageUrl, imageDescription }) => ({ src: imageUrl, caption: imageDescription, header: ' ' }))} />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    {errorDeleting && <div style={{ textAlign: "center", color: "red" }}>
                        Error Deleting Program
                        </div>}
                    <div style={{ float: "right" }}>
                        <Button color="danger" onClick={(e) => deleteProgram(e)}>Delete</Button>
                        {' '}
                        <Button color="primary" onClick={() => setIsEditing(true)}>Edit</Button>
                    </div>
                </CardFooter>
            </Card>
        </div>
    )
}

export default ProgramCard;