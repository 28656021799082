import HeroImage from '../Images/HeroImage.png';
import DonateImage from '../Images/Donate.png';
// import EmergencyAid from '../Images/EmergencyAid.png';
// import Qurbani from '../Images/Qurbani.png';
// import FamilyAssistance from '../Images/FamilyAssistance.png';
import VerticalDivider from '../Icons/VerticalDivider.svg';
import Icon from '../Icons/Icon.svg';
// import PlayButton from '../Icons/PlayButton.png';
import ArrowIcon from '../Icons/ArrowIcon.svg';
import { useNavigate } from "react-router-dom";

// import DonationForm from './DonationForm';

const Divider = () => <img src={VerticalDivider} alt="divider" />;

// const ProgramCard = ({ src, alt, title, body }) => (
//     <div className="ProgramCard">
//         <div className="ProgramCardImage">
//             <img src={src} alt={alt} />
//             <div className="ProgramCardContent">
//                 <div className="ProgramCardContentTitle">{title}</div>
//                 <div className="ProgramCardContentBody">{body}</div>
//             </div>
//             <div className="ButtonSecondary" style={{ width: "195px", marginTop: "10px" }}>Learn More</div>
//         </div>
//     </div>
// )
const Home = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="HeroImageContainer" style={{ backgroundImage: `url(${HeroImage})` }}>
                <div className="HeroImageContent">
                    <div className="HeroCopyCallout">
                        it starts with you
                    </div>

                    <div className="HeroCopyHeading">
                        Your donation can make all the difference.
                    </div>

                    <div className="HeroCopyBody">
                        Khayr Foundation’s mission is to make a difference in real lives by providing food, water, shelter, sanitation, clothing, medicine, and education. We also take nontraditional approaches that uplift communities by providing a positive environment for human development, eco-centric developmental projects, and opportunities for individuals and communities to maximize their potential.
                    </div>

                    <div className="ButtonPrimary" onClick={() => navigate("/donation")}>Make a Donation</div>
                </div>
            </div>
            <div className="StatsBar">
                <div className="StatsBarItem">
                    <div className="StatsBarNumber">$20,000</div>
                    <div className="StatsBarLabel">Dollars Raised</div>
                </div>
                <Divider />
                <div className="StatsBarItem">
                    <div className="StatsBarNumber">50+</div>
                    <div className="StatsBarLabel">Volunteers</div>
                </div>
                <Divider />
                <div className="StatsBarItem">
                    <div className="StatsBarNumber">10,000</div>
                    <div className="StatsBarLabel">Meals Donated</div>
                </div>
                <Divider />
                <div className="StatsBarItem">
                    <div className="StatsBarNumber">12</div>
                    <div className="StatsBarLabel">Programs</div>
                </div>
            </div>
            <div className="IntroBlock">
                <div className="Icon"><img src={Icon} alt="icon" /> </div>
                <div className="IntroBlockCallout">reimagine charity with khayr foundation</div>
                <div className="IntroBlockHeading">Transformative action with honesty and transparency.</div>
                <div className="IntroBlockBody">Khayr Foundation performs worldwide humanitarian efforts, as long with social and educational services, to uplift and stabilize communities in need. In addition to personal rewards, numerous studies have shown that those who give more to others experience greater satisfaction in life than those who do not. Traditional charities are limited in their abilities due to large administrative and marketing costs. Khayr Foundation was established to transfer the totality of charitable contributions to funding activities that benefit end-recipients, ensuring that each donation achieves maximum impact.</div>
                <div className="CTAButtons">
                    {/* <div className="ButtonPrimary" style={{ width: '250px' }}>About Us</div> */}
                    <div className="ButtonPrimary" style={{ width: '250px' }} onClick={() => navigate("/programs")} >Our Programs</div>
                </div>
            </div>
            <div className="Spacer" />
            <div className="DonateContainer" style={{ backgroundImage: `url(${DonateImage})` }}>
                <div className="DonateContent">
                    <div className="DonateContentCopy">
                        <div className="DonateContentCopyCallout">Emergency Appeal</div>
                        <div className="DonateContentCopyHeading">Winter relief in Pakistan</div>
                        <div className="DonateContentCopyBody">With the help of your donation, Khayr Foundation USA volunteers will provide food, water, shelter, and blankets to communities across Pakistan who are currently facing unprecedented weather conditions and a harsh winter season.</div>
                        {/* <div className="DonateContentCopyVideoLink">
                            <span className="DonateContentCopyVideoLinkText">Our volunteers in action</span> <img src={PlayButton} alt="play button" />
                        </div> */}
                    </div>
                    <div className="DonateLinkListContainer">
                        <div className="DonateLinkListContainerTitle" onClick={() => navigate("/donation")}>
                            <div className="DonateLinkListContainerTitleText">
                                Make a Donation
                            </div>
                            <div className="DonateLinkListContainerTitleArrowIcon">
                                <img src={ArrowIcon} alt="Arrow Icon" />
                            </div>
                        </div>
                        {/* <div className="DonateLinkListContainerTitle">
                            <div className="DonateLinkListContainerTitleText">
                                Find us on GoFundMe
                            </div>
                            <div className="DonateLinkListContainerTitleArrowIcon">
                                <img src={ArrowIcon} alt="Arrow Icon" />
                            </div>
                        </div>
                        <div className="DonateLinkListContainerTitle">
                            <div className="DonateLinkListContainerTitleText">
                                Find Us On LaunchGood
                            </div>
                            <div className="DonateLinkListContainerTitleArrowIcon">
                                <img src={ArrowIcon} alt="Arrow Icon" />
                            </div>
                        </div> */}
                        {/* <div className="DonateLinkListContainerTitle">
                            <div className="DonateLinkListContainerTitleText">
                                Financial Disclosures
                            </div>
                            <div className="DonateLinkListContainerTitleArrowIcon">
                                <img src={ArrowIcon} alt="Arrow Icon" />
                            </div>
                        </div> */}
                    </div>
                    {/* <DonationForm /> */}
                </div>
            </div>
            {/* <div className="Programs">
                <div className="ProgramCards">
                    <ProgramCard src={EmergencyAid} alt="tent" title="Emergency Aid" body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                    <ProgramCard src={FamilyAssistance} alt="man praying" title="Family Assistance" body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                    <ProgramCard src={Qurbani} alt="three goats" title="Qurbani" body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                </div>
            </div> */}
            {/* <div className="Form">
                <div className="FormContent">
                    <div className="FormContentIcon">
                        <img src={Icon} alt="icon" />
                    </div>
                    <div className="FormContentCallout">Get in Touch</div>
                    <div className="FormContentHeading">Learn more about us or join our mission to make a global impact.</div>
                </div>
                <div className="FormContactForm">
                    <input className="FormContactFormTextField" type="text" placeholder="Full Name" />
                    <input className="FormContactFormTextField" type="email" placeholder="Email Address" />
                    <select className="FormContactFormTextField" name="hear_about_us" id="hear_about_us">
                        <option value="" disabled>How did you hear about us?</option>
                        <option value="advertisement">Advertisement</option>
                    </select>
                    <textarea className="FormContactFormTextArea" placeholder="Your Message" />
                    <div className="ButtonPrimary" style={{ background: "#E57937" }}>Submit</div>
                </div>
            </div> */}
        </>
    )
}

export default Home;
