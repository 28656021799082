import { useState } from "react";
import { Button, Card, CardFooter, Container, Form, FormGroup, Input, Label, Badge } from "reactstrap";
import { API_URL } from "../config";
import ProgramCard from "./ProgramCard";

const LoginScreen = ({ setLoggedIn, setAdminAPIKey, adminAPIKey }) => {
    const [invalidAPIKey, setInvalidAPIKey] = useState(false);

    const login = async () => {
        const res = await fetch(`${API_URL}/login`, {
            headers: {
                'x-api-key': adminAPIKey
            }
        });

        if (!res.ok) throw Error(res.statusText);
        setLoggedIn(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(e.target.value);
        try {
            await login();
            setInvalidAPIKey(false);
        } catch (error) {
            setInvalidAPIKey(true);
        }
    }

    const handleChange = (e) => {
        setAdminAPIKey(e?.target?.value);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Input type="password" name="apiKey" id="apiKey" placeholder="Enter Admin API Key" onChange={handleChange} />
                {invalidAPIKey && <p style={{ color: 'red' }}> Invalid API Key</p>}
            </FormGroup>
        </Form>
    )
}

const AddProgramForm = ({ setAddingProgram, adminAPIKey, fetchPrograms }) => {
    const [newProgram, setNewProgram] = useState({
        name: '',
        description: '',
        videoUrl: '',
        regions: [],
        slideshowPhotos: [{
            imageUrl: '',
            imageDescription: '',
        }],
    });


    const [errorAddingProgram, setErrorAddingProgram] = useState();
    const [regionInput, setRegionInput] = useState('');

    const handleChange = ({ target: { name, value } }) => {
        const _newProgram = { ...newProgram };

        if (name.startsWith('slideshowPhoto:imageUrl')) {
            const i = parseInt(name.split('slideshowPhoto:imageUrl:')[1]);
            _newProgram.slideshowPhotos[i].imageUrl = value;
        } else if (name.startsWith('slideshowPhoto:imageDescription')) {
            const i = parseInt(name.split('slideshowPhoto:imageDescription:')[1]);
            _newProgram.slideshowPhotos[i].imageDescription = value;
        } else {
            _newProgram[name] = value;
        }

        setNewProgram(_newProgram);
    }

    const handleAddRegion = (e) => {
        e.preventDefault();
        if (regionInput) {
            setNewProgram({
                ...newProgram,
                regions: [...newProgram.regions, regionInput]
            });
            setRegionInput('');
        }
    }

    const handleRemoveRegion = (regionToRemove) => {
        setNewProgram({
            ...newProgram,
            regions: newProgram.regions.filter(region => region !== regionToRemove)
        });
    }

    const addProgram = async () => {
        const res = await fetch(`${API_URL}/programs`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': adminAPIKey
            },
            // body: '{"name": "Inmate Family Assistance", "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." ,"slideshowPhotos": ["https://cdn.theatlantic.com/media/mt/science/cat_caviar.jpg", "https://thumbs.dreamstime.com/b/kitten-lies-money-dollars-euros-looks-frame-holding-bills-its-paws-concept-wealth-prosperity-photo-196044184.jpg"] , "videoUrl": "https://www.youtube.com/watch?v=GpcaJQ40q1Y&ab_channel=RoyaltyFree%E2%80%94Videosforcontentcreators" }',
            body: JSON.stringify(newProgram)
        });
        if (!res.ok) {
            setErrorAddingProgram(true);
            return;
        }

        await fetchPrograms();
        setErrorAddingProgram(false);
        setAddingProgram(false);
    }

    return (
        <div style={{ marginBottom: '50px' }}>
            <Card>
                <Container>
                    <Form>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input type="text" name="name" id="name" value={newProgram.name} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <Input rows="10" type="textarea" name="description" id="description" value={newProgram.description} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Embedded YouTube video url</Label>
                            <Input type="text" name="videoUrl" id="videoUrl" value={newProgram.videoUrl} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Slideshow photos</Label>
                            {newProgram.slideshowPhotos.map((photo, i) =>
                                <div key={i}>
                                    <Label>Image Url</Label>
                                    <Input type="text" name={`slideshowPhoto:imageUrl:${i}`} id={`slideshowPhoto:imageUrl:${i}`} value={photo.imageUrl} onChange={handleChange} />
                                    <Label>Image Description</Label>
                                    <Input type="text" name={`slideshowPhoto:imageDescription:${i}`} id={`slideshowPhoto:imageDescription:${i}`} value={photo.imageDescription} onChange={handleChange} />
                                </div>
                            )}
                            <div style={{ marginTop: '10px' }}>
                                <Button
                                    color="danger"
                                    disabled={newProgram.slideshowPhotos.length <= 1}
                                    onClick={(e) => { e.preventDefault(); setNewProgram({ ...newProgram, slideshowPhotos: [...newProgram.slideshowPhotos.slice(0, newProgram.slideshowPhotos.length - 1)] }) }}
                                >-</Button>{' '}
                                <Button
                                    color="primary"
                                    disabled={newProgram.slideshowPhotos.length >= 10}
                                    onClick={(e) => { e.preventDefault(); setNewProgram({ ...newProgram, slideshowPhotos: [...newProgram.slideshowPhotos, { imageUrl: '', imageDescription: '' }] }) }}
                                >+</Button>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>Regions</Label>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                                <Input type="text" value={regionInput} onChange={(e) => setRegionInput(e.target.value)} />
                                <Button color="primary" style={{ margin: '10px' }} onClick={handleAddRegion}>Add Region</Button>
                            </div>
                            {newProgram.regions.map((region, i) =>
                                <Badge color="primary" key={i} style={{ marginRight: '10px', marginBottom: '10px' }}>
                                    {region}
                                    <span
                                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                                        onClick={() => handleRemoveRegion(region)}
                                    >
                                        X
                                    </span>
                                </Badge>
                            )}
                        </FormGroup>
                    </Form>
                </Container>
                <CardFooter>
                    {errorAddingProgram && <div style={{ textAlign: "center", color: "red" }}>
                        Error Adding Program
                        </div>}
                    <div style={{ float: "right" }}>
                        <Button color="danger" onClick={() => setAddingProgram(false)}>Cancel</Button>
                        {' '}
                        <Button color="primary" onClick={() => addProgram(true)}>Add</Button>
                    </div>
                </CardFooter>
            </Card>
        </div>
    )
}

const AdminPage = ({ programs, fetchPrograms }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [adminAPIKey, setAdminAPIKey] = useState();
    const [addingProgram, setAddingProgram] = useState();


    if (!loggedIn) {
        return (
            <Container className="AdminPage">
                <LoginScreen setLoggedIn={setLoggedIn} adminAPIKey={adminAPIKey} setAdminAPIKey={setAdminAPIKey} />
            </Container>
        )
    }

    return (
        <Container className="AdminPage">
            <Button style={{ marginBottom: "10px" }} color="primary" onClick={() => setAddingProgram(true)}>Add Program</Button>
            {addingProgram && <AddProgramForm
                setAddingProgram={setAddingProgram}
                adminAPIKey={adminAPIKey}
                fetchPrograms={fetchPrograms}
            />}
            <div>
                {programs.map(p => <ProgramCard program={p} adminAPIKey={adminAPIKey} fetchPrograms={fetchPrograms} />)}
            </div>
        </Container>
    )
}

export default AdminPage;