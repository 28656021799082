import { Container, UncontrolledCarousel } from "reactstrap";

const { useParams } = require("react-router");

const Program = ({ programs }) => {
    const { programName } = useParams();
    if (!programs) return;

    const program = programs.find(({ SK: name }) => name === programName);
    if (!program) return '404 Not Found';

    const {
        videoUrl,
        description,
        slideshowPhotos,
    } = program;


    return (
        <Container style={{ marginBottom: '100px' }}>
            <h1> {programName}</h1>
            <p>{description}</p>
            <div style={{ height: "50vh" }}>
                <iframe
                    width="100%"
                    height="100%"
                    src={videoUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <div style={{ marginTop: "20px" }}>
                <UncontrolledCarousel items={slideshowPhotos.map(src => ({ src, captionText: '' }))} />
            </div>
        </Container>
    )
}

export default Program;